/* COLOR PALETTE
green: #34d399 (rgb(52 211 153))
pink: #d334bd (rgb(211 52 189))
orange: #d34a34ff (rgba(211, 74, 52))
*/

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter:300,400,600&display=swap');

/* ============= GLOBAL STYLES ============= */

/* Style the body to ensure a uniform background and text color */
body {
  margin: 0;
  padding: 0;
  background-color: #0E0E0E; /* The dark background color you want */
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* The Navbar is fixed; you can add padding-top if needed to avoid overlap */
}

/* ============= NAVBAR STYLES ============= */

.navbar {
  display: flex;
  align-items: center;
  background-color: #0E0E0E;
  position: absolute;
  width: 100%;
  z-index: 999;
}

/* Three Flex Children: .navbar-left, .navbar-center, .navbar-right */
.navbar-left,
.navbar-center,
.navbar-right {
  flex: 1; /* Each takes equal horizontal space */
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
}

/* Left container: brand link pinned to the left */
.navbar-left {
  justify-content: flex-start;
  padding-left: 1rem;
}

/* Center container: perfect center for logo */
.navbar-center {
  justify-content: center;
}

/* Right container: menu pinned to the right */
.navbar-right {
  justify-content: flex-end;
  position: relative;
  padding-right: 1rem; /* optional spacing */
}

/* Brand link styles */
.navbar-link {
  color: #fff;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  text-decoration: none;
}

.navbar-link:hover {
  color: #ddd;
}

.navbar-menu-button {
  background: #111111;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
}

.navbar-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #0E0E0E;
  min-width: auto;
  padding: 0rem 0.75rem;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
  pointer-events: none;
}

.navbar-dropdown.open {
  transform: scaleY(1);
  opacity: 1;
  pointer-events: auto;
}

.navbar-dropdown-link {
  display: block;
  padding: 0.75rem 0.75rem;
  text-decoration: none;
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  transition: background-color 0.2s;
}

.navbar-dropdown-link:hover {
  background-color: #222;
}

/* ============= RESPONSIVE STYLES FOR MOBILE ============= */
@media (max-width: 768px) {
  /* Reduce the brand link font size */
  .navbar-link {
    font-size: 1.5rem;
  }
  
  /* Adjust the menu button for smaller screens */
  .navbar-menu-button {
    font-size: 1.2rem;
    padding: 0.5rem 0.75rem;
  }
  
  /* Adjust the dropdown links for a more compact design */
  .navbar-dropdown-link {
    font-size: 1.2rem;
    padding: 0.5rem 0.75rem;
  }
}

/* ============= HOME (HERO) SECTION STYLES ============= */

/* The hero container that holds the background slideshow */
.hero-section {
  min-height: 75vh;
  padding-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* A container to ensure the text is left-aligned but block is centered */
.hero-text-container {
  width: fit-content;
  text-align: left;
}

/* The hero title text itself */
.hero-title {
  margin: 0;
  line-height: 1.3;
  font-size: clamp(2rem, 5vw, 4rem);
  color: #fff; /* In case you want to ensure white text */
}

@media (max-width: 768px) {
  /* Reduce the brand link font size */
  .hero-section {
    min-height: 67.5vh;
  }
}


/* ======================= BLOG ====================== */
.blog {
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #fff;
}

.blog h2 {
  padding-top: 5rem;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin-top: 2.5vh;
  max-width: auto;
}

.blog-card {
  font-family: 'Inter', sans-serif;
  color: #fff;
  text-decoration: none;
  position: relative;
  height: 20vh;
  width: 20vh;
  display: flex;
  flex-direction: column;
  align-items: end;
  border-radius: 0px;
  transition: transform 0.2s;
}

.blog-card::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.blog-card-title {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: right;
}

.blog-card-date {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 0.2rem 0.4rem;
  font-size: 0.9rem;
  text-align: right;
}


/* ============== POST ================== */

.post {
  margin: 0 auto;
  min-height: 75vh;
}

.post h1 {
  color: #fff;
}

.post-content {
  margin-top: 0vh;
  color: #fff;
  max-width: auto;
}

table {
  width: auto;
  border-collapse: collapse;
  margin: 1rem 0;
}
th, td {
  padding: 0.5rem;
  text-align: left;
  background-color: #222;
  border: 1px solid yellow;
}

/* ============== NEW POST ================== */

.post-input {
  min-height: 80vh;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;
}

.post-input h2 {
  padding-top: 5rem;
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

#content, #adminPassword {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Inter', sans-serif;
  background-color: #222;
  color: #fff;
  border: #222;
  border-radius: 2.5px;
}

.post-input button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto 0 auto;
  padding: 0.75rem 2rem;
  background-color: #331;
  color: #fff;
  border: #222;
  border-radius: 15px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

/* ================== EDIT A POST =================== */

.edit-blog-entry {
  min-height: 80vh;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;
}

.edit-blog-entry h2 {
  padding-top: 5rem;
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}


/* Textarea for editing blog content */
.edit-textarea {
  width: 100%;
}

/* Delete button styling */
.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto 0 auto;
  padding: 0.75rem 2rem;
  background-color: #331;
  color: #fff;
  border: #222;
  border-radius: 15px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

#deleteButton {
  background-color: red;
}

/* Message styling */
.message {
  color: #34d399; /* example green */
  margin-top: 1rem;
}

/* Error message styling */
.error-message {
  color: red;
  margin-top: 1rem;
}
